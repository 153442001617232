import { useWorkflowUser } from "@/hooks";
import { buildYearMonth } from "@/lib/utils/date";
import { NextPage } from "next";
import { useRouter, usePathname } from "next/navigation";
import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";

const Index: NextPage = () => {
  const router = useRouter();
  const {
    user,
    isClerk,
    isManager,
    isAdmin,
    isStaff,
    isSiteManager,
    isAreaManager,
  } = useWorkflowUser();
  const pathname = usePathname();

  useEffect(() => {
    setTimeout(() => {
      if (!user?.id) {
        if (location.pathname !== "/login") router.push("/login");
        return;
      }
      const hasRoles = user?.roles;
      if (pathname === "/") {
        if (hasRoles) {
          if (isClerk || isManager || isAdmin || isAreaManager) {
            const yearMonth = buildYearMonth();
            router.push(`/manager/workrecord/${yearMonth}/all`);
          } else if (isSiteManager) {
            router.push("/staff");
          } else {
            router.push("/staff/workrecord/new");
          }
        }
      }
    }, 1);
  }, [
    pathname,
    router,
    user,
    isClerk,
    isManager,
    isAdmin,
    isStaff,
    isSiteManager,
    isAreaManager,
  ]);

  const isNotLocalEnvironment = process.env.NEXT_PUBLIC_ENVIRONMENT;
  useEffect(() => {
    // dataDogのユーザー情報を設定
    isNotLocalEnvironment &&
      datadogRum.setUser({
        id: user.id || "",
        name: user.displayName || "",
        // 以下は任意項目
        displayName: user.displayName,
        roles: user.roles,
        groups: user.groups,
      });
  }, [user, isNotLocalEnvironment]);

  return <div></div>;
};
export default Index;
